<template>
  <section class="section flex home-carousel">
    <div class="section-header f fs">
      <h2>{{ $t("homepage.features.title") }}</h2>
      <svg class="wh14 ml10">
        <use xlink:href="/icons/sprite.svg#i_blink" />
      </svg>
    </div>
    <div v-if="items?.length > 0" class="section-body">
      <div class="carousel">
        <Swiper
            v-if="items?.length > 0"
            :grabCursor="true"
            :loop="false"
            :slides-per-view="'auto'"
            @init="onSwiperInit"
        >
          <SwiperSlide v-for="(item, index) of items" :key="`${item.icon}-${index}`">
            <div
                class="item-card"
            >
              <div class="item-card__top">
                <svg class="item-card__top-svg">
                  <use :xlink:href="`/icons/sprite.svg#${item.icon}`" />
                </svg>
                <nuxt-link
                    :to="item.to"
                    class="item-card__top-link"
                >
                  {{ item.label }}
                </nuxt-link>
              </div>
              <!--              <span class="light">-->
              <!--                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum, omnis.-->
              <!--              </span>-->

              <!--              <span class="btn btn-link-arrow">-->
              <!--                  {{ t('show_more') }}-->
              <!--              </span>-->
            </div>
          </SwiperSlide>
        </Swiper>
        <div
            :class="{disabled: carousel?.activeIndex === 0}"
            class="carousel-arrow arrow-prev"
            @click="prev"
        >
          <i
              class="i-arrow"
          ></i>
        </div>
        <div
            :class="{disabled: carousel?.isEnd}"
            class="carousel-arrow arrow-next"
            @click="next">
          <i
              class="i-arrow"
          ></i>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts" setup>
import {ref} from 'vue'
import {Swiper, SwiperSlide} from 'swiper/vue';
import 'swiper/swiper-bundle.css';
import type {Swiper as SwiperClass} from 'swiper';

const localePath = useLocalePath()
const {t} = useI18n()
const items = [
  {
    to: {path: localePath('/comparison'), query: {}},
    icon: 'i-chart',
    label: t("homepage.features.Items price comparison")
  },
  {
    to: localePath('/search'),
    icon: 'i-search-list',
    label: t("homepage.features.Advanced search")
  },
  {
    to: localePath('/inventory'),
    icon: 'i-dollar',
    label: t('inventory.checker.title')
  },
  {
    to: localePath('/markets'),
    icon: 'i-archive',
    label: t("cs2_markets")
  },
  {
    to: localePath('/trade-up'),
    icon: 'i-swap',
    label: t('trade_up_contract')
  },
  {
    to: localePath('/teams'),
    icon: 'community',
    label: t("teams_label")
  },
  {
    to: localePath(`/colors`),
    icon: 'palette',
    label: t("сolor_groups")
  },
  {
    to: localePath('/blog'),
    icon: 'i-book',
    label: t("blog")
  },
]

const carousel = ref<SwiperClass | null>(null);

const next = () => {
  if (carousel.value) {
    carousel.value.slideNext();
  }
};

const prev = () => {
  if (carousel.value) {
    carousel.value.slidePrev();
  }
};

const onSwiperInit = (e: any) => {
  if (!carousel.value) {
    carousel.value = e
  }
}

</script>
<style lang="scss" scoped>
@use "/assets/scss/components/carousel";

.carousel {
  min-height: unset;

  .swiper-slide {
    width: auto;
    min-width: 176px;
    height: auto;
    align-self: stretch;
  }

  .carousel-arrow {
    opacity: 1;
    cursor: pointer;

    &.disabled {
      opacity: 0.5;
      cursor: default;
    }
  }

  .item-card {
    margin: 0 6px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    padding: 2.4rem;
    border-radius: 8px;
    background: var(--surfaces-low-container);
    transition: 0.3s;
    cursor: pointer;
    width: 230px;
    min-height: 170px;
    position: relative;
    overflow: hidden;

    &:hover {
      background: color-mix(in srgb, var(--surfaces-low-container) 98%, white);
    }


    &:after {
      content: '';
      position: absolute;
      left: -100px;
      bottom: -150px;
      z-index: 1;
      width: 250px;
      height: 250px;
      background: radial-gradient(50% 50% at 50% 50%, color-mix(in srgb, var(--accent-primary-container) 60%, transparent) 0%, color-mix(in srgb, var(--accent-on-surface) 5%, transparent) 80%, transparent 100%);
    }

    .item-card__top {
      width: 100%;
      color: var(--surfaces-on-surface);

      &-svg {
        position: absolute;
        flex-shrink: 0;
        width: 45px;
        height: 45px;
        color: var(--accent-primary);
        padding: 4px;
        right: 16px;
        bottom: 16px;
        z-index: 2;
      }

      &-link {
        font-size: 2.1rem;
        line-height: 2.8rem;
        font-weight: 700;
        text-decoration: none;
        color: var(--surfaces-on-surface);

        &:after {
          content: '';
          position: absolute;
          inset: 0;
          z-index: 3;
        }
      }
    }

    //.btn-link-arrow {
    //  padding: 4px 0;
    //  font-size: 1.2rem;
    //  color: var(--surfaces-on-surface-variable);
    //  margin-top: auto;
    //}
  }
}
</style>
