<template>
  <NuxtLink
      :aria-label="`Item link to ${ item.market_hash_name }`"
      :rel="linkObj.rel ? 'ugc' : ''"
      :to="linkObj.link"
      class="item_wrap"
  >
    <div class="item_top f gray">
      <ItemCardComponentsExteriorAB
          :name="item.market_hash_name"
      />
      <ItemCardComponentsCap
          :price-diffs="item.price_difference"
      />
    </div>
    <div class="item_body">
      <ItemCardComponentsImage :item="item" :loading="loading" />
      <ItemCardComponentsName :item="item" />
    </div>
    <div class="f item_footer">
      <div class="btn btn-primary fg">
        <img
            v-if="market"
            :alt="market"
            :src="getImageFromProxy(`${imagesUrl}markets/${market}.svg`, '50:50')"
            class="wh24 round mr8"
            height="24"
            loading="lazy"
            width="24"
        />
        <ItemCardComponentsPrice
            :price="item.price || 0"
        />
      </div>
    </div>
  </NuxtLink>
</template>

<script lang="ts" setup>
import type {ItemsPriceDiffs} from "~/graphql/generated/schema-types";
import maxExtItems from '~/data/max-ext.json'

const props = defineProps<{
  item: ItemsPriceDiffs,
  loading?: 'lazy' | 'eager'
  market?: string
}>()
const config = useRuntimeConfig()
const imagesUrl = config.public.imagesUrl

const linkObj = computed(() => {
  const itemIncludes = maxExtItems.includes(props.item.market_hash_name) || props.item.market_hash_name.includes('Factory New')
  if (itemIncludes) {
    return {
      link: getItemLinkWithoutExt({
        name: props.item.market_hash_name,
        slug: props.item.link
      }),
      rel: false
    }
  }
  if (props.item.market_hash_name.includes('Field-Tested') ||
      props.item.market_hash_name.includes('Minimal Wear') ||
      props.item.market_hash_name.includes('Well-Worn') ||
      props.item.market_hash_name.includes('Battle-Scarred')
  ) {
    return {
      link: getItemLinkWithExterior({
        name: props.item.market_hash_name,
        slug: props.item.link
      }),
      rel: true
    }
  }

  return {
    link: getItemLinkWithExterior({
      name: props.item.market_hash_name,
      slug: props.item.link
    }),
    rel: false
  }
})

</script>

<style lang="scss" scoped>

@use 'sass:color';

.item {
  &_wrap {
    display: flex;
    flex-direction: column;
    background: var(--surfaces-low-container);
    border-radius: 0.8rem;
    padding: 1.6rem;
    font-size: 1.2rem;
    transition: 0.3s;
    position: relative;
    max-width: 180px;
    height: 100%;

    &:hover,
    &:focus {
      outline: none;
      background: color-mix(in srgb, var(--surfaces-low-container) 98%, white);

      .thumb {
        border-color: color-mix(in srgb, var(--surfaces-low-container) 98%, white);
      }
    }

    .price {
      margin: 1.6rem 0 0.6rem;
      min-height: 2.5rem;
    }
  }

  &_top {
    position: absolute;
    z-index: 1;
    width: 100%;
    top: 0;
    left: 0;
    padding: 1.6rem;
  }

  &_footer {
    margin-top: auto;

    .btn-primary:deep(span) {
      margin-left: 0;
    }
  }
}

.buy_toolbar {
  font-size: 1.2rem;
  margin-top: 1.5rem;

  .btn-primary {
    flex-grow: 1;
    margin-right: 1rem;
  }
}
</style>
